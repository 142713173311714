import { useState, useLayoutEffect } from 'react'

let defaultWidth, defaultHeight
const isBrowser = typeof window !== 'undefined'

if (isBrowser) {
  defaultWidth = window.innerWidth
  defaultHeight = window.innerHeight
}

export default function useWindowDimensions() {
  const [screenDimensions, setScreenDimensions] = useState({
    windowWidth: defaultWidth,
    windowHeight: defaultHeight,
  })

  useLayoutEffect(() => {
    if (!isBrowser) return

    function setDimensions() {
      setScreenDimensions(() => {
        return {
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        }
      })
    }

    window.addEventListener('resize', setDimensions)

    return () => {
      window.removeEventListener('resize', setDimensions)
    }
  }, [])

  return [screenDimensions.windowWidth, screenDimensions.windowHeight]
}
